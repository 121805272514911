/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import './contacto.css'
import { useEffect } from 'react'
import facebookIcon from '../../../assets/images/footer/facebook-icon-1.png'
import twitterIcon from '../../../assets/images/footer/twitter-icon.png'
import linkedinIcon from '../../../assets/images/footer/linkedin-icon.png'

const Contacto = (): any => {
  let showSuccess = false
  let showError = false
  const { t } = useTranslation()
  useEffect(() => {
    const form = document.getElementById('contact-form')
    if (form != null) {
      form.addEventListener('submit', function (e) {
        e.preventDefault()
        const xhr = new XMLHttpRequest()
        xhr.open('POST', 'https://jfwz9jkeha.execute-api.eu-west-1.amazonaws.com/default/contact-form-lambda')
        xhr.addEventListener('load', function () {
          if (xhr.status === 200) {
            showSuccess = true
          } else {
            showError = true
          }
        })
        xhr.addEventListener('error', function () {
          showError = true
        })
        const contactForm = document.getElementById('contact-form') as HTMLFormElement
        const formData = new FormData(contactForm)
        const jsonData = JSON.stringify(Object.fromEntries(formData))
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(jsonData)
      })
    }
  }, [])

  return (
    <div>
      <div className='container mb-5'>
        <div className='mt-5 mb-4'><h3 className='fw-bold'>{ t('contacto') }</h3><hr /></div>
        <hr className='separator' />
        <div className='row'>
          <div className='col-lg-6 col-sm-12 text-start fs-4 left-col'>
            <h4 className='fw-bold'>{ t('dondeEstamos') } </h4>
            <p className='direction'>{ t('direccion') }</p>
            <p className='direction'>28045 Madrid</p>
            <p className='text-primary maill'><a href="mailto:info@criterian.es">info@criterian.es</a></p>
            <div className='socialmedia'>
              <a href="https://www.linkedin.com/in/criterian-software-305241135" target="_blank" rel="noreferrer">
                <img decoding="async" title="https://www.linkedin.com/in/criterian-software-305241135" src={ linkedinIcon } alt="linkedIn" width="32" height="32" />
              </a>
              <a href="https://www.facebook.com/criteriansoftware/" target="_blank" rel="noreferrer" >
                <img decoding="async" title="https://www.facebook.com/criteriansoftware/" src={ facebookIcon } alt="facebook" width="32" height="32" /></a>
              <a href="https://twitter.com/Criterian_Softw/" target="_blank" rel="noreferrer">
                <img decoding="async" title="https://twitter.com/Criterian_Softw/" src={ twitterIcon } alt="twitter" width="32" height="32" />
              </a>
            </div>
            <iframe width="95%" height="500" className='mb-5' title='Maps' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=C.%20de%20Juan%20de%20Mariana,%2015+(talent%20garden)&amp;t=&amp;z=14&amp;ie=UTF8&amp; iwloc=B&amp;output=embed">
            </iframe>
          </div>
          <div className='col-lg-6 col-sm-12 fs-5 right-col'>
            <h4 className='fw-bold'>{ t('contacta') }</h4>
            <form className='text-start' id='contact-form'>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label fw-bold is-required" htmlFor="form-name">{ t('nombre') } { t('requerido') }</label>
                    <input type="text" id="form-name" name='form-name' className="form-control rounded-0" autoComplete='on' required />
                  </div>
                </div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label fw-bold is-required" htmlFor="form-email">{ t('email') } { t('requerido') }</label>
                <input type="email" id="form-email" name='form-email' className="form-control rounded-0" autoComplete='on' required />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label fw-bold" htmlFor="form-subject">{ t('asunto') }</label>
                <input type="text" id="form-subject" name='form-subject' className="form-control rounded-0" />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label fw-bold" htmlFor="form-message">{ t('message') }</label>
                <textarea className="form-control h-100 rounded-0  w-75 mensaje-contacto" name='form-message' id="form-message" rows={ 6 }></textarea>
              </div>
              <button type="submit" className="btn enviar btn-block mb-4 fs-5 border" value="enviar">{ t('enviar') }</button>
            </form>
          </div>
          <div id="alert-success" style={ { display: showSuccess ? 'block' : 'none' } } className="alert alert-success">
            <strong>{ t('exito') }</strong> { t('mensajeExito') }
            <div><span className='success-x' >X</span></div>
          </div>
          <div id="alert-error" style={ { display: showError ? 'block' : 'none' } } className="alert alert-danger">
            <strong>{ t('error') }</strong> { t('mensajeError') }
            <div><span className='error-x' >X</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contacto
