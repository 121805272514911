/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import mapsIcon from '../../../assets/images/footer/Maps-icon.png'
import mailIcon from '../../../assets/images/footer/Mail-icon.png'
import facebookIcon from '../../../assets/images/footer/facebook-icon-1.png'
import twitterIcon from '../../../assets/images/footer/twitter-icon.png'
import linkedinIcon from '../../../assets/images/footer/linkedin-icon.png'
import './Footer.css'
import { useLocation } from 'react-router-dom'

const Footer = (): any => {
  const { t } = useTranslation()
  const location = useLocation()
  return (
    <div id="sidebar-footer" className="footer-widgets visibility-all" style={ { display: location.pathname === '/politica-de-cookies' ? 'none' : 'block' } }>
      <div className="container">
        <div className="footer-widgets-grid">
          <div className="sidebar-column">
            <aside id="text-7" className="widget">
              <div className="textwidget footerMailDirection">
                <div className="aligncenter"><img decoding="async" src={ mapsIcon } alt="Maps" width="32" height="32" /></div>
                <p><span className="tL8wMe">{ t('direccion') }<br />Madrid<br /></span><span className="tL8wMe">28045</span></p>
              </div>
            </aside>
          </div>
          <div className="sidebar-column">
            <aside className="widget widget_text">
              <div className="textwidget footerMailDirection">
                <div className="aligncenter"><img decoding="async" src={ mailIcon } alt="email" width="32" height="32" /></div>
                <p>Email:<a href="mailto:info@criterian.es" target="blank" rel="noreferrer"><br />info@criterian.es </a></p>
              </div>
            </aside>
          </div>
          <div className="sidebar-column" id='footer-blankspace'>
          </div>
          <div className="sidebar-column">
            <aside className="widget widget_text">
              <div className="textwidget footerRedesSociales">
                <div>
                  <a href="https://www.facebook.com/criteriansoftware/" target="_blank" rel="noreferrer" >
                    <img decoding="async" title="https://www.facebook.com/criteriansoftware/" src={ facebookIcon } alt="Facebook" width="32" height="32" /></a>
                </div>
                <div>
                  <a href="https://twitter.com/Criterian_Softw/" target="_blank" rel="noreferrer">
                    <img decoding="async" title="https://twitter.com/Criterian_Softw/" src={ twitterIcon } alt="Twitter" width="32" height="32" />
                  </a>
                </div>
                <div>
                  <a href="https://www.linkedin.com/in/criterian-software-305241135" target="_blank" rel="noreferrer">
                    <img decoding="async" title="https://www.linkedin.com/in/criterian-software-305241135" src={ linkedinIcon } alt="LinkedIn" width="32" height="32" />
                  </a>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
