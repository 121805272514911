/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import './cookieWarning.css'
import { NavLink, useLocation } from 'react-router-dom'

const CookieWarning = (): any => {
  const { t } = useTranslation()
  const location = useLocation()

  function close (): any {
    const cookieBox = document.getElementById('cookieBox') as HTMLElement
    cookieBox.className = 'cookieclosed'
  }
  return (
    <div id="cookieBox" style={ { display: location.pathname === '/politica-de-cookies' ? 'none' : 'block' } }>
      <p>{ t('cookies') }</p>
      <button type="button" id='botonCookie' onClick={ close } className="btn btn-secondary">{ t('cerrar') }</button>
      <p>{ t('leermas') } <NavLink to='/politica-de-cookies'>{ t('leermas-enlace') }</NavLink></p>
    </div>
  )
}

export default CookieWarning
