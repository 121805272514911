/* eslint-disable react/react-in-jsx-scope */
import Caso from './caso'
import './casosExito.css'
import { datosArbor, datosRFHE, datosEntrena, datosSavso, datosGaia, datosPella, datosAxisPharma } from './casos'
const CasosExito = (): any => {
  return (
    <div className='container casosexito' id='main'>
      <Caso data={ datosArbor } />
      <Caso data={ datosRFHE } />
      <Caso data={ datosEntrena } />
      <Caso data={ datosSavso } />
      <Caso data={ datosGaia } />
      <Caso data={ datosPella } />
      <Caso data={ datosAxisPharma } />
    </div>

  )
}
export default CasosExito
