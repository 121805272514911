/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import './cookieWarning.css'

const CookiePolicy = (): any => {
  const { t } = useTranslation()
  return (
    <div id='politica-cookies'>
      <h1>{ t('politica-cookies-titulo') }</h1>
      <h2>{ t('para-que-cookies-titulo') }</h2><p>{ t('para-que-cookies') }</p>
      <h2>{ t('que-cookies-titulo') }</h2><p>{ t('que-cookies') }</p>
      <h2>{ t('desactivar-cookies-titulo') }</h2><p>{ t('desactivar-cookies') }</p>
      <h2>{ t('cookies-datos') }</h2>
      <div className='recogetabla'>
        <table className='table table-striped table-bordered table-responsive'>
          <thead>
            <tr>
              <th scope='col'> { t('cookie-nombre') } </th>
              <th scope='col'> { t('cookie-proveedor') } </th>
              <th scope='col'> { t('cookie-categoria') } </th>
              <th scope='col'> { t('cookie-funcion') } </th>
              <th scope='col'> { t('cookie-caducidad') } </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row'> _ga </th>
              <td> Google LLC </td>
              <td> ANALYTICS </td>
              <td> { t('cookie-_ga-funcion') } </td>
              <td> { t('cookie-_ga-tiempo') }</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CookiePolicy
