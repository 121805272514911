/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { useEffect } from 'react'
import './inicio.css'
import slide1 from '../../../assets/images/inicio/desarrollo-software-slide-new.png'
import slide2 from '../../../assets/images/inicio/due-diligence-slide-new.png'
import slide3 from '../../../assets/images/inicio/desarrollo-fintech-slide-new.png'
import slide4 from '../../../assets/images/inicio/concepto-realidad-slide-new.png'
import inicioscr from '../../../assets/images/inicio/criterian-home-aux-img.png'
import { Carousel } from 'bootstrap'

const Inicio = (): any => {
  useEffect(() => {
    const carouselEl = document.getElementById('carouselinicio') as HTMLElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const carouselInstance = new Carousel(carouselEl)
  }, [])
  const { t } = useTranslation()
  return (
    <div>
      <div id="carouselinicio" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active ">
            <img src={ slide1 } className="d-block " alt={ t<string>('carrusel1-alt') } />
            <div className="carousel-caption inicio-caption rounded-end">
              <h1>{ t('carrusel1-cabecera') }</h1>
              <p>{ t('carrusel1-texto') }</p>
            </div>
          </div>
          <div className="carousel-item" >
            <img src={ slide2 } className="d-block " alt={ t<string>('carrusel2-alt') } />
            <div className="carousel-caption inicio-caption rounded-end">
              <h1>{ t('carrusel2-cabecera') }</h1>
              <p>{ t('carrusel2-texto') }</p>
              <Link className="carouselLink" to="https://due-diligence.criterian.es/" target="_blank">{ t('carrusel2-enlace') }</Link>
            </div>
          </div>
          <div className="carousel-item" >
            <img src={ slide3 } className="d-block " alt={ t<string>('carrusel3-alt') } />
            <div className="carousel-caption inicio-caption rounded-end-3">
              <h1>{ t('carrusel3-cabecera') }</h1>
              <p>{ t('carrusel3-texto') }</p>
            </div>
          </div>
          <div className="carousel-item" >
            <img src={ slide4 } className="d-block " alt={ t<string>('carrusel4-alt') } />
            <div className="carousel-caption inicio-caption rounded-end">
              <h1>{ t('carrusel4-cabecera') }</h1>
              <p>{ t('carrusel4-texto') }</p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev inicio-control" type="button" data-bs-target="#carouselinicio" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next inicio-control" type="button" data-bs-target="#carouselinicio" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {/* Segunda parte del inicio */ }
      <div className="row mb-5 mt-5 presentacion-inicio">
        <div className="col-md-11 ">
          <div className="row g-0 overflow-hidden flex-md-row mb-4  position-relative ps-4">
            <div className="col p-4 d-flex flex-column position-static text-start">
              <h3 className="mb-0">{ t('inicio-cabecera') }</h3>
              <p className="card-text mb-auto pe-3">
                <Trans i18nKey="inicio-descripcion1">
                </Trans>
              </p>
              <p>
                <Trans i18nKey="inicio-descripcion2">
                </Trans>
              </p>
              <p>
                <Trans i18nKey="inicio-descripcion3">
                </Trans>
              </p>
            </div>
            <div className="col-auto ">
              <img src={ inicioscr } alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Inicio
