/* eslint-disable react/react-in-jsx-scope */
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from './../../../assets/images/header/cropped-cropped-criterian_transparencia.png'
import spainflag from './../../../assets/images/header/es.png'
import ukflag from './../../../assets/images/header/gb.png'
import './Header.css'

const Header = ({ cambiarIdioma }: any): any => {
  const { i18n, t } = useTranslation()
  const onChangeLang = (langCode: string): any => {
    void i18n.changeLanguage(langCode)
    cambiarIdioma(langCode)
  }
  const location = useLocation()

  return (
    <header className="sticky-top" style={ { display: location.pathname === '/politica-de-cookies' ? 'none' : 'block' } }>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <div className="logo navbar-brand">
            <a href="/" title="Criterian"><img src={ logo } alt="inicio" /></a>
          </div>
          <button className="navbar-toggler shadow-none " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarNavDropdown" >
            <ul className="navbar-nav" >
              <li className="nav-item log">
                <button className="navbar-toggler shadow-none " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <a href="/" title="Criterian" id="logo-index"><img src={ logo } alt="inicio" /></a><span className="x"><svg width="25" height="25" fill="blue" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"></path></svg></span>
                </button>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link " aria-current="page" to="/">{ t('inicio') }</NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="productos-y-servicios/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                  { t('servicios') }
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><NavLink className="dropdown-item" to="productos-y-servicios/">{ t('desarrollo de software') }</NavLink></li>
                  <li><NavLink className="dropdown-item" to="https://due-diligence.criterian.es/" target="_blank">{ t('due') }</NavLink></li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="la-empresa/">{ t('empresa') }</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="casos-de-exito/">{ t('exitos') }</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="contacto/" id="contacto">{ t('contacto') }</NavLink>
              </li>
              <li className="nav-item idiomas">
                <NavLink className="nav-link" to="#"><img decoding="async" onClick={ () => { onChangeLang('es') } } src={ spainflag } alt="Spanish/Español" /></NavLink>
              </li>
              <li className="nav-item idiomas">
                <NavLink className="nav-link contact" to="#"><img decoding="async" onClick={ () => { onChangeLang('en') } } src={ ukflag } alt="English/Inglés" /></NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
export default Header
